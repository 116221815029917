<template>
    <section id="trash" v-if="!loading">
      <v-card
        class="d-flex flex-column align-center justify-center pb-10 pa-5 custom-border border"
        height="500"
        v-if="trash.length === 0"
      >
        <div class="">
          <v-img max-width="300" :src="require('../../assets/default/empty_trash.png')" />
        </div>
        <h3 class="poppins fw600 my-5">
            Trash is Empty
        </h3>
        <h4 class=" secondary-1--text fw400 text-center">
            Move files you don’t need anymore in Trash. All of the data on this trash will be permanently deleted after 30 days. 
        </h4>
      </v-card>
      <v-card v-else class="custom-border border pa-5">
        <div class="d-flex justify-end align-center">
          <v-btn 
            text 
            small
            @click="gallery =  true"
            class="mx-1 hidden-sm-and-down"
          >
            <v-icon size="22" :class="gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-view-gallery-outline
            </v-icon>
          </v-btn>
          <v-btn 
            text 
            small
            @click="gallery = false"
            class="mx-1 hidden-sm-and-down"
          >
            <v-icon size="22" :class="!gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
        </div>

        <v-card-text v-if="trash.length>0" class="d-flex" :class="[$vuetify.breakpoint.mobile && 'justify-center', gallery ? 'flex-wrap' : 'flex-column']">
          <CourseCardGallery 
            v-if="gallery"
            v-for="(item,i) in trash"
            :key="item.id"
            :i="i" 
            :item="item" 
            :gallery="gallery"
            class="ma-3"
            @showRestoreSuccess="showRestoreSuccess"
          />
          <CourseCardList 
            v-if="!gallery"
            v-for="(item,i) in trash"
            :key="item.id"
            :i="i" 
            :item="item" 
            class="ma-3"
            @showRestoreSuccess="showRestoreSuccess"
          />
        </v-card-text>
      </v-card>
      
      <DeleteAllDialogVue :dialog="deleteDialog" @close="deleteDialog=false"/>
    </section>
    <circular v-else />
  </template>
  
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import CourseCardGallery from "../../components/teacher/course/CourseCardGallery.vue";
import CourseCardList from "../../components/teacher/course/CourseCardList.vue";
import DeleteAllDialogVue from "../../components/teacher/trash/DeleteAllDialog.vue";

  export default {
    components: {
      CourseCardGallery,
      DeleteAllDialogVue,
      CourseCardList
    },
    data: () => ({
      loading: true,
      gallery: true,
      deleteDialog: null,
      asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
    }),
    computed: {
      ...mapState("instructor", {
        trash: (state) => state.trash,
      }),
    },
    created(){
      this.getTrashAction().then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false
        this.alertMutation({
          show: true,
          text: 'Something went wrong.',
          type: "error"
        })
      })
    },
    mounted() {
      this.getTrashAction().then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false
        this.alertMutation({
          show: true,
          text: 'Something went wrong.',
          type: "error"
        })
      })
      this.onResize()
    },
    methods: {
      ...mapActions("instructor", ["getTrashAction", "getCoursesAction"]),
      ...mapMutations(["alertMutation"]),
      
        showRestoreSuccess(){
          this.alertMutation({
              show: true,
              text: 'Your file has been restored.',
              type: "success"
          })
          this.getCoursesAction();
        },
        onResize(){
          if(this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.mobile){
            this.gallery = false
          }
        }
    },
    mounted() {
      window.addEventListener('resize', this.onResize, {passive: true})
    }
  };
  </script>  